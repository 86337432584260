<template>
    <div class="home">
      <div class="pt20"></div>

      <div class="pt20"></div>
      <div class="left">
        <el-button v-if="add == 1" @click="goNav('/menu/MenuAdd')" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(3)" type="danger" style="font-size: 16px;font-weight: bold;" plain>删除</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(1)" type="success" style="font-size: 16px;font-weight: bold;" plain>启用</el-button>
        <el-button v-if="dele == 1" @click="menuChangeStatus(2)" type="warning" style="font-size: 16px;font-weight: bold;" plain>禁用</el-button>
      </div>
      <div class="pt20"></div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
        
        <el-table-column
          prop="sort" 
          label="菜单顺序">
        </el-table-column>
        <el-table-column
          prop="name"
          label="菜单名称"
          width="80">
        </el-table-column>
        
        <el-table-column
          prop="sort" 
          label="展开"
          type="expand">
          <template v-if="scope.row._child" slot-scope="scope">
              <el-table
                :data="scope.row._child"
                style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
                @selection-change="handleSelectionChange" >
       <el-table-column type="selection" width="55" align="center"/>
                 <el-table-column
                    prop="sort" 
                    label="菜单顺序">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    label="菜单名称"
                    width="80">
                    </el-table-column>
                    <el-table-column
                    prop="sort" 
                    label="展开"
                    type="expand">
                    <template v-if="scope.row._child" slot-scope="scope">
                        <el-table
                            :data="scope.row._child"
                            style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
                            @selection-change="handleSelectionChange" >
                                <el-table-column type="selection" width="55" align="center"/>
                                  <el-table-column
                                  prop="sort" 
                                  label="菜单顺序">
                                  </el-table-column>
                                  <el-table-column
                                  prop="name"
                                  label="菜单名称"
                                  width="80">
                                </el-table-column>
                                <el-table-column
                    prop="sort" 
                    label="展开"
                    type="expand">
                    <template v-if="scope.row._child" slot-scope="scope">
                        <el-table
                            :data="scope.row._child"
                            style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
                            @selection-change="handleSelectionChange" >
                                <el-table-column type="selection" width="55" align="center"/>
                                  <el-table-column
                                  prop="sort" 
                                  label="菜单顺序">
                                  </el-table-column>
                                  <el-table-column
                                  prop="name"
                                  label="菜单名称"
                                  width="80">
                                </el-table-column>
                                <el-table-column
                    prop="sort" 
                    label="展开"
                    type="expand">
                    <template v-if="scope.row._child" slot-scope="scope">
                        <el-table
                            :data="scope.row._child"
                            style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
                            @selection-change="handleSelectionChange" >
                                <el-table-column type="selection" width="55" align="center"/>
                                  <el-table-column
                                  prop="sort" 
                                  label="菜单顺序">
                                  </el-table-column>
                                  <el-table-column
                                  prop="name"
                                  label="菜单名称"
                                  width="80">
                                </el-table-column>
                                <el-table-column
                                  prop="url"
                                  label="路径">
                                </el-table-column>
                                
                                <el-table-column
                                  prop="is_show"
                                  label="是否展示在导航栏">
                                  <template slot-scope="scope">
                                    {{$utils.dict.Semantization(scope.row.is_show)}}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="status_text"
                                  label="状态">
                                </el-table-column>
                                <el-table-column
                                  prop="status"
                                  label="操作">
                                  <template slot-scope="scope">
                                <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
                                  </template>
                                </el-table-column>
                        </el-table>
                    </template>
                    </el-table-column>
                                <el-table-column
                                  prop="url"
                                  label="路径">
                                </el-table-column>
                                
                                <el-table-column
                                  prop="is_show"
                                  label="是否展示在导航栏">
                                  <template slot-scope="scope">
                                    {{$utils.dict.Semantization(scope.row.is_show)}}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="status_text"
                                  label="状态">
                                </el-table-column>
                                <el-table-column
                                  prop="status"
                                  label="操作">
                                  <template slot-scope="scope">
                                <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
                                  </template>
                                </el-table-column>
                        </el-table>
                    </template>
                    </el-table-column>
                                <el-table-column
                                  prop="url"
                                  label="路径">
                                </el-table-column>

                                <el-table-column
                                  prop="is_show"
                                  label="是否展示在导航栏">
                                  <template slot-scope="scope">
                                    {{$utils.dict.Semantization(scope.row.is_show)}}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="status_text"
                                  label="状态">
                                </el-table-column>
                                <el-table-column
                                  prop="status"
                                  label="操作">
                                  <template slot-scope="scope">
                                <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
                                  </template>
                                </el-table-column>
                        </el-table>
                    </template>
                    </el-table-column>
                    <el-table-column
                      prop="url"
                      label="路径">
                    </el-table-column>
                    <el-table-column
          prop="is_show"
          label="是否展示在导航栏">
          <template slot-scope="scope">
            {{$utils.dict.Semantization(scope.row.is_show)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="status_text"
          label="状态">
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
          </template>
        </el-table-column>
             </el-table>
          </template>
        </el-table-column>

        <el-table-column
          prop="url"
          label="路径">
        </el-table-column>
        <el-table-column
          prop="is_show"
          label="是否展示在导航栏">
          <template slot-scope="scope">
            {{$utils.dict.Semantization(scope.row.is_show)}}
          </template>
        </el-table-column>
        <el-table-column
          prop="status_text"
          label="状态">
        </el-table-column>
        <el-table-column
          prop="status"
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      page:1,
      pageSize:30,
      tableData: [],
      formInline: {
        user: "",
        region: "",
        pageSize: 10,
        pageNo: 1
      },
      // 选中数组
      ghs: [],
     //选中的记录数量
     selectedNum:0,
    }
  },
  methods: {
      goNav(url){
        this.$router.push(url);
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
       // this.$router.push('/menu/MenuEdit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/menu/MenuEdit');
      },
      menuChangeStatus(status){
          let _this = this;
        if(this.selectedNum == 0){
          this.$message.error('请先勾选');
          return;
        }
        if(this.selectedNum != 1){
          this.$message.error('不支持批量');
          return;
        }
        this.$confirm('确认执行操作？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(config.menuChangeStatus, {menu_id:this.ghs[0],status:status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.$emit('getrefresh')
            _this.init();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });

      },
    handleSizeChange(){},
    handleCurrentChange(){},
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.menu_id)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
        console.log(this.ghs);
        console.log(this.selectedNum);
      },
    init(){
        let _this = this;
        axios.get(config.menu,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          console.log(error);
        });
      }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
    text-align: left;
}
</style>